export const twinsGetModelQuery = `
query TwinsGetModel($input: TwinsGetModelInput!) {
  twinsGetModel(input: $input) {
      success
      message
      data { 
        id
        name
        description
        type
        role
        provider
        model
        options { 
          temperature
          maxTokens
          stability
          exaggeration
          clarity
          streamingLatency
          useSpeakerBoost
          aggregatorOn
          aggregatorCharacters
          aggregatorTimer
        }
        voiceID
        language
        currentVersion
        username
        userID
        modelConfigID
        createdAt
        updatedAt
      }
    }
  }`;

export const twinsGetPromptQuery = `
query TwinsGetPrompt($input: TwinsGetPromptInput!) {
  twinsGetPrompt(input: $input) {
      success
      message
      data { 
        id
        name
        description
        currentVersion
        modelConfigID
        promptLayerName
        promptLayerActive
        role
        position
        content
        modelID
        username
        userID
        createdAt
        updatedAt
      }
    }
  }`;

export const twinsGetDataSourceQuery = `
query TwinsGetDataSource($input: TwinsGetDataSourceInput!) {
  twinsGetDataSource(input: $input) {
      success
      message
      data { 
        id
        type
        name
        description
        currentVersion
        username
        userID
        modelConfigID
        createdAt
        updatedAt
      }
    }
  }`;

export const twinsGetFeedbackQuery = `
query TwinsGetFeedback($input: TwinsGetFeedbackInput!) {
  twinsGetFeedback(input: $input) {
      success
      message
      data { 
        id
        modelConfigID
        submittedBy
        username
        userID
        promptVersionID
        modelVersionID
        dataSourceID
        evaluationID
        content
        type
        rating
        createdAt
        updatedAt
      }
    }
  }`;

export const twinsGetModelConfigQuery = `
query TwinsGetModelConfig($input: TwinsGetModelConfigInput!) {
  twinsGetModelConfig(input: $input) {
      success
      message
      data { 
        id
        name
        description
        username
        userID
        active
        createdAt
        updatedAt
      }
    }
  }
  `;

export const twinsGetSignedUrlQuery = `
  query TwinsGetSignedUrl ($input: TwinsGetSignedUrlInput!) {
    twinsGetSignedUrl(input: $input) {
      type
      url
      headers
      uploadId
    }
  }
`;

export const twinsGetUserQuery = `query TwinsGetUserHandler($input: TwinsGetUserInput!) {
  twinsGetUser(input: $input) {
    totalCount,
      user {
        id
        userId
        username
        email
        firstName
        lastName
        dateCreated
        dateDeleted
        isFlagged
        gender
        lastActivity
        stats { 
          follows
          hidden
          connected
          followedBy
        }
        profilePictureUri
        flags { 
          deactivatedAt
          deleteReason
          deletedAt
          lastDeactivatedAt
        }
        interactions { 
          sendMessage
          sendRequest
        }
      }
  }
}`;

export const twinsSearchPostsQuery = `query TwinsSearchPosts($input: TwinsSearchPostsInput!) {
  twinsSearchPosts(input: $input) {
      totalCount
      posts { 
        id
        transcript
        audience
        authorUserId
        authorId
        caption
        author { 
          id
          username
          userId
        }
        dateCreated
        metadata { 
          duration
          framesPerSecond
          language
        }
        transcript
        type
        videoStartTimestamp
        videoStopTimestamp
      }
  }
}`;

export const twinsGetCollectionQuery = `query TwinsGetCollection($input: TwinsGetCollectionInput!) {
  twinsGetCollection(input: $input) {
    success
    message
    data { 
      id
      name
      location
      date
      createdBy
      username
      userID
      interviewer
      type
      status
      jobID
      createdAt
      updatedAt
    }
  }
}`;

export const twinsGetFileQuery = `query TwinsGetFile($input: TwinsGetFileInput!) {
  twinsGetFile(input: $input) {
    success
    message
    data { 
      id
      name
      medallion
      key
      size
      length
      content
      status
      type
      collectionID
      speakerID
      interviewer
      jobID
      createdBy
      speakers
      stage
      username
      userID
      selected
      createdAt
      updatedAt
    }
  }
}`;

export const twinsGetJobQuery = `query TwinsGetJob($input: TwinsGetJobInput!) {
  twinsGetJob(input: $input) {
    success
    message
    data { 
      id
      createdBy
      username
      userID
      collectionID
      fileName
      fileID
      message
      s3Key
      s3Bucket
      batchJobId
      transcriptMinChars
      transcriptMaxChars
      transcriptLanguage
      removeMultiSpeaker
      status
      type
      stage
      action
      samples {
        speaker
        transcriptURI
        audioURI
        transcript { 
          speaker
          transcript
          startTime
          endTime
        }
      }
      startedAt
      endedAt
      createdAt
      updatedAt
    }
  }
}`;

export const twinsGetVoiceQuery = `
query TwinsGetVoice($input: TwinsGetVoiceInput!) {
  
  twinsGetVoice(input: $input) {
      success
      message
      data {
        id
        name
        collectionID
        description
        apiKey
        apiUserID
        manifestURI
        provider
        type
        model
        username
        userID
        sampleID
        language
        transcriptMinChars
        transcriptMaxChars
        transcriptLanguage
        removeMultiSpeaker
        step
        status
        selectAll
        createdBy
        createdAt
        updatedAt
        }
      }
    }
`;

export const twinsSearchUsersQuery = `query TwinsSearchUsers($input: TwinsSearchUsersInput!) {
  twinsSearchUsers(input: $input) { 
    users {
      id
      userId
      username
      sub
      firstName
      lastName
      dateOfBirth
      email
      phone
      gender
      genderDesc
      dateCreated
      dateDeleted
      lastActivity
      profilePictureUri
      aboutMe
      isFlagged
      connectionStatus
    }
    totalCount
  }
}
`;

export const twinsGetMemoryQuery = `
query TwinsGetMemory($input: TwinsGetMemoryInput!) {
  twinsGetMemory(input: $input) {
      success
      message
      data { 
        id
        collectionName
        modelConfigID
        type
        ingestedFeels
        active
        chunkSize
        overlapPercentage
        username
        userID
        createdAt
        updatedAt
      }
    }
  }`;

export const twinsGetDocumentQuery = `
query TwinsGetDocument($input: TwinsGetDocumentInput!) {
  twinsGetDocument(input: $input) {
      success
      message
      data { 
        id
        content
        collectionName
        modelConfigID
        username
        userID
        link
        fileName
        fileType
        fileExtension
        category
        createdAt
        updatedAt
      }
    }
  }`;

export const twinsGetTagsByVoiceID = `query GetTagsByVoiceId($voiceID: ID!) {
    getTagsByVoiceId(voiceID: $voiceID) {
      id
      tag
    }
  }`;

export const twinsGetVoiceByTag = `query ListVoicesByTag($tag: String!) {
    listVoicesByTag(tag: $tag) {
      id
      name
      description
      # other fields
    }
  }
  `;
export const twinsGetVoiceByTagAndUser = `query ListVoicesByTagAndUser($tag: String!, $userID: ID!) {
  listVoicesByTagAndUser(tag: $tag, userID: $userID) {
    id
    name
    description
    # other fields
  }
}
`;
export const twinsGetJobPreviewQuery = `query TwinsGetJobPreview($input: TwinsGetJobPreviewInput!) {
  twinsGetJobPreview(input: $input) {
    success
    message
    data { 
      posts { 
        id
      }
      totalCount
    }
  }
}`;

export const twinsGetJobLogQuery = `query TwinsGetJobLog($input: TwinsGetJobLogInput!) {
  twinsGetJobLog(input: $input) {
    success
    message
    data { 
      id
      jobID
      userID
      status
      message
      createdAt
      updatedAt
    }
  }
}`;
export const twinsGetSignedCloudfrontUrlQuery = `query TwinsGetSignedCloudfrontUrl($input: TwinsGetSignedCloudfrontUrlInput!) {
  twinsGetSignedCloudfrontUrl(input: $input) {
    success
    message
    uri
  }
}`;
