import {
  Box,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { Model, ModelOptions, Voice } from '@twins/types';
import { menuOptions } from 'common/utils';
import {
  Select as SFSelect,
  ModelSettingsItem,
  Slider,
  ButtonIconTooltip,
} from 'components/atoms';
import { AIModels, AIProvider } from 'types/enums';

interface AggregationSettingsProps {
  ttsModel: Model | undefined;
  onChange: (
    field: keyof Model | keyof ModelOptions,
    value: number | boolean,
  ) => void;
}

export function AggregationSettings({
  ttsModel,
  onChange,
}: AggregationSettingsProps) {
  return (
    <Grid
      item
      xs={12}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <FormLabel>Approximate Characters</FormLabel>
          <TextField
            sx={{ mt: 1 }}
            type="number"
            disabled={!ttsModel?.options?.aggregatorOn}
            value={ttsModel?.options?.aggregatorCharacters ?? 0}
            onChange={(e) =>
              onChange('aggregatorCharacters', Number(e.target.value))
            }
            fullWidth
            size="small"
            placeholder="How many characters to approximately aggregate"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <FormLabel>Max Time Limit (ms)</FormLabel>
          <TextField
            sx={{ mt: 1 }}
            type="number"
            disabled={!ttsModel?.options?.aggregatorOn}
            value={ttsModel?.options?.aggregatorTimer ?? 0}
            onChange={(e) =>
              onChange('aggregatorTimer', Number(e.target.value))
            }
            fullWidth
            size="small"
            placeholder="Hard limit of time to wait before sending to TTS"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

interface FormSectionProps {
  children: React.ReactNode;
}

export function FormSection({ children }: FormSectionProps) {
  return (
    <Box sx={{ mb: 4 }}>
      <Grid
        container
        spacing={2}
      >
        {children}
      </Grid>
    </Box>
  );
}

interface VoiceProviderSelectProps {
  options: AIProvider[];
  value: string | undefined;
  onChange: (field: keyof Model | keyof ModelOptions, value: string) => void;
}

export function VoiceProviderSelect({
  options,
  value,
  onChange,
}: VoiceProviderSelectProps) {
  return (
    <ModelSettingsItem label="Provider">
      <SFSelect
        value={value || AIProvider.PLAYHT}
        defaultValue={AIProvider.PLAYHT}
        onChange={(providerValue) => onChange('provider', providerValue)}
        options={menuOptions(options)}
      />
    </ModelSettingsItem>
  );
}

interface VoiceSelectProps {
  voices: Voice[];
  value: string | undefined;
  onChange: (field: keyof Model | keyof ModelOptions, value: string) => void;
  onAddVoice: () => void;
  loadingVoices: boolean;
}

export function VoiceSelect({
  voices,
  value,
  onChange,
  onAddVoice,
  loadingVoices,
}: VoiceSelectProps) {
  return (
    <ModelSettingsItem
      label="Voice"
      action={
        <ButtonIconTooltip
          size="small"
          onClick={onAddVoice}
          isLoading={loadingVoices}
          tooltipTitle="Add Voice"
          icon="add"
          tooltipColorVariant="info"
        />
      }
    >
      <Select
        disabled={loadingVoices || voices?.length === 0}
        fullWidth
        value={value || ''}
        onChange={(e) => onChange('voiceID', e.target.value)}
        size="small"
        placeholder={
          voices?.length > 0
            ? 'Select a voice'
            : 'No voices found for this provider.'
        }
      >
        {voices.map((voice) => (
          <MenuItem
            key={voice.id}
            value={voice.id}
          >
            {voice.name}
          </MenuItem>
        ))}
      </Select>
    </ModelSettingsItem>
  );
}

export interface SliderSetting {
  label: string;
  field: keyof ModelOptions;
  max: number;
  step: number;
  value: number;
}

interface VoiceSettingsProps {
  settings: SliderSetting[];
  onChange: (field: keyof ModelOptions, value: number) => void;
}

export function VoiceSettings({ settings, onChange }: VoiceSettingsProps) {
  return (
    <Grid
      item
      xs={12}
    >
      <Stack
        direction="column"
        spacing={2}
        sx={{ pt: 2 }}
      >
        {settings.map((setting) => (
          <Slider
            key={setting.label}
            max={setting.max}
            step={setting.step}
            columns={3}
            label={setting.label}
            value={setting.value}
            setValue={(value) => onChange(setting.field, value)}
          />
        ))}
      </Stack>
    </Grid>
  );
}

interface VoiceModelSelectProps {
  models: AIModels[];
  value: AIModels | undefined;
  onChange: (field: keyof Model | keyof ModelOptions, value: string) => void;
}

export function VoiceModelSelect({
  models,
  value,
  onChange,
}: VoiceModelSelectProps) {
  return (
    <ModelSettingsItem label="Voice Model">
      <SFSelect
        value={value || AIModels.PLAY_HT_2_TURBO}
        defaultValue={AIModels.PLAY_HT_2_TURBO}
        onChange={(modelValue) => onChange('model', modelValue)}
        options={menuOptions(models)}
      />
    </ModelSettingsItem>
  );
}
