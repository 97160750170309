import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import {
  AIModels,
  AIProvider,
  AIType,
  Languages,
  MemoryType,
  PromptPosition,
  PromptRoles,
} from 'types/enums';
import { useSuperfeelUser } from 'use/superfeel-user';
import { createPrompt } from './prompt';

export const useDefaultTemplate = () => {
  const { superfeelUser } = useSuperfeelUser();

  const modelConfigID = useMemo(() => uuid(), []);

  const startConversationPromptInput = useMemo(
    () => ({
      id: uuid(),
      name: 'First Message',
      description: 'Starts the conversation',
      content: createPrompt({
        firstName: superfeelUser?.firstName,
        lastName: superfeelUser?.lastName,
        position: PromptPosition.START,
      }),
      username: superfeelUser?.username,
      userID: superfeelUser?.userId,
      modelConfigID,
      role: PromptRoles.SYSTEM,
      position: PromptPosition.START,
      currentVersion: 1,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }),
    [superfeelUser, modelConfigID],
  );

  const mainSystemPromptInput = useMemo(
    () => ({
      id: uuid(),
      name: 'Main System Prompt',
      description: 'Main system prompt',
      content: createPrompt({
        firstName: superfeelUser?.firstName,
        lastName: superfeelUser?.lastName,
        position: PromptPosition.MAIN,
      }),
      username: superfeelUser?.username,
      userID: superfeelUser?.userId,
      modelConfigID,
      role: PromptRoles.SYSTEM,
      position: PromptPosition.MAIN,
      currentVersion: 1,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }),
    [superfeelUser, modelConfigID],
  );

  const llmInput = useMemo(
    () => ({
      id: uuid(),
      name: 'OpenAI',
      description: 'Main LLM - OpenAI GPT-4o',
      provider: AIProvider.OPENAI,
      model: 'gpt-4o',
      type: AIType.LLM,
      options: {
        temperature: 0.2,
        maxTokens: 512,
      },
      currentVersion: 1,
      username: superfeelUser?.username,
      userID: superfeelUser?.userId,
      modelConfigID,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }),
    [superfeelUser, modelConfigID],
  );

  const ttsInput = useMemo(
    () => ({
      id: uuid(),
      name: 'PlayHT',
      description: 'Professional voice clone',
      provider: AIProvider.PLAYHT,
      model: AIModels.PLAY_HT_2_TURBO,
      type: AIType.TTS,
      options: {
        stability: 0.9,
        clarity: 0.2,
        exaggeration: 0.1,
        streamingLatency: 0,
        useSpeakerBoost: false,
        aggregatorOn: false,
        aggregatorCharacters: 100,
        aggregatorTimer: 500,
      },
      currentVersion: 1,
      username: superfeelUser?.username,
      userID: superfeelUser?.userId,
      modelConfigID,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }),
    [superfeelUser, modelConfigID],
  );

  const asrInput = useMemo(
    () => ({
      id: uuid(),
      name: 'DeepGram',
      description: 'Streaming ASR',
      provider: AIProvider.DEEPGRAM,
      model: AIModels.NOVA_2,
      type: AIType.ASR,
      language: Languages.EN,
      currentVersion: 1,
      username: superfeelUser?.username,
      userID: superfeelUser?.userId,
      modelConfigID,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }),
    [superfeelUser, modelConfigID],
  );
  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, '0');
  };
  const memoryInput = useMemo(
    () => ({
      id: uuid(),
      collectionName: `${superfeelUser?.username}-local-${generateRandomNumber()}`,
      modelConfigID,
      username: superfeelUser?.username,
      userID: superfeelUser?.userId,
      type: MemoryType.LOCALVECTOR,
      active: false,
      chunkSize: 250,
      overlapPercentage: 20,
    }),
    [superfeelUser, modelConfigID],
  );

  return {
    modelConfigID,
    template: {
      startPrompt: startConversationPromptInput,
      mainPrompt: mainSystemPromptInput,
      llm: llmInput,
      tts: ttsInput,
      asr: asrInput,
      memory: memoryInput,
    },
  };
};
